







































































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useAssetCategory,
  useBlob,
  useContactData,
  useLocalFilter,
  useUnitMargin
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  DataListAssetCategory,
  DataListMasterAsset
} from "@/models/interface/assets.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  MarginUnitHeadReportResponseDTO,
  MarginUnitReportResponseDTO
} from "@/models/interface/ReportMarginUnit.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { AddressDataDto } from "@interface/contact-data";
import moment, { Moment } from "moment";
import Vue from "vue";

type TableRow = Row<MarginUnitReportResponseDTO>;
type FormValue = {
  branchId: string | null;
  unitCodeId: string | null;
  invoiceDate: Array<Moment> | null;
  rwoDate: Array<Moment> | null;
  poDate: Array<Moment> | null;
  customerId: string | null;
  customerLocation: string | null;
  assetCategoryId: string | null;
};

export default Vue.extend({
  name: "UnitMargin",
  mixins: [MNotificationVue],
  data() {
    this.getListCustomer = debounceProcess(this.getListCustomer);
    this.getUnitCode = debounceProcess(this.getUnitCode);
    this.getBranch = debounceProcess(this.getBranch);
    this.onSearchAssetCategory = debounceProcess(this.onSearchAssetCategory);
    return {
      DEFAULT_DATE_FORMAT,
      dataSource: [] as Array<TableRow>,
      invoiceColumn: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "invoiceNo",
          key: "invoiceNo",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "invoiceDate",
          key: "invoiceDate",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "invoiceStatus",
          key: "invoiceStatus",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "invoiceQty",
          key: "invoiceQty",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_price"),
          dataIndex: "invoicePrice",
          key: "invoicePrice",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_invoice_ar_amount"),
          dataIndex: "invoiceDpp",
          key: "invoiceDpp",
          scopedSlots: { customRender: "currency" },
        },
      ],
      rwoColumn: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "rwoNumber",
          key: "rwoNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "rwoDate",
          key: "rwoDate",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "rwoStatus",
          key: "rwoStatus",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "rwoPartNumber",
          key: "rwoPartNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "rwoPartName",
          key: "rwoPartName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "rwoUom",
          key: "rwoUom",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "rwoQty",
          key: "rwoQty",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_cogs"),
          dataIndex: "rwoCogs",
          key: "rwoCogs",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_total"),
          dataIndex: "rwoTotal",
          key: "rwoTotal",
          scopedSlots: { customRender: "currency" },
        },
      ],
      poColumn: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "poNumber",
          key: "poNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "poDate",
          key: "poDate",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "poStatus",
          key: "poStatus",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "polDescription",
          key: "polDescription",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "poPartNumber",
          key: "poPartNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "poPartName",
          key: "poPartName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "poUom",
          key: "poUom",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "poQty",
          key: "poQty",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_cogs"),
          dataIndex: "poPrice",
          key: "poPrice",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_total"),
          dataIndex: "poTotal",
          key: "poTotal",
          scopedSlots: { customRender: "currency" },
        },
      ],
      columns: [
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "assetCode",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_dpp_total"),
          dataIndex: "totalDpp",
          key: "dpp",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_rwo_total"),
          dataIndex: "totalRwo",
          key: "totalRwo",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_po_total"),
          dataIndex: "totalPo",
          key: "poTotal",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_margin_total"),
          dataIndex: "margin",
          key: "totalMargin",
          scopedSlots: { customRender: "currency" },
        },
      ],
      loading: {
        category: false,
        listCustomer: false,
        table: false,
        submit: false,
        download: false,
        unitCode: false,
        branch: false,
      },
      params: {} as RequestQueryParamsModel,
      dataListCustomer: [] as ContactData[],
      dataListCustomerLocation: [] as Array<Option<AddressDataDto>>,
      dataUnitCode: [] as DataListMasterAsset[],
      dataBranch: [] as DataWarehouseBranch[],
      form: this.$form.createForm(this, { name: "unitMarginReport" }),
      formRules: {
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: ["customerId"],
        },
        customerLocation: {
          label: "lbl_customer_location",
          name: "customerLocation",
          placeholder: "lbl_customer_location_placeholder",
          decorator: ["customerLocation"],
        },
        invoiceDate: {
          label: "lbl_invoice_date",
          name: "invoiceDate",
          decorator: ["invoiceDate"],
        },
        rwoDate: {
          label: "lbl_rwo_date",
          name: "rwoDate",
          decorator: ["rwoDate"],
        },
        poDate: {
          label: "lbl_po_date",
          name: "poDate",
          decorator: ["poDate"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["unitCodeId"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: ["branchId"],
        },
        assetCategory: {
          label: "lbl_asset_category",
          name: "assetCategory",
          placeholder: "lbl_asset_category",
          decorator: ["assetCategoryId"],
        },
      },

      optAssetCategory: [] as Array<Option<DataListAssetCategory>>,

      dataReport: {
        grandTotalDpp: 0,
        grandTotalRwo: 0,
        grandTotalPo: 0,
        grandTotalMargin: 0,
        data: [],
      } as MarginUnitHeadReportResponseDTO,
    };
  },
  created() {
    this.getListCustomer("");
    this.getUnitCode("");
    this.getBranch("");
    this.getAssetCategory(new RequestQueryParams());
  },
  methods: {
    moment,
    useLocalFilter,
    handleChangeCustomer(custId: string): void {
      const { toContactLocationOptions } = useContactData();
      const option = this.dataListCustomer.find(item => item.id === custId);
      this.dataListCustomerLocation = toContactLocationOptions(
        option?.addressDataList || []
      );
    },
    getUnitCode(valueSearch: string) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loading.unitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(response => {
          this.dataUnitCode = response.data;
        })
        .finally(() => (this.loading.unitCode = false));
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loading.branch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loading.branch = false));
    },
    getListCustomer(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true_AND_active~true",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.listCustomer = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListCustomer = response.data))
        .finally(() => (this.loading.listCustomer = false));
    },
    async handleDownload(): Promise<void> {
      const { toDownload } = useBlob();
      const { download: downloadReport } = useUnitMargin();
      const { filterBy, buildDownloadParam } = useUnitMargin();

      try {
        const values: FormValue = this.form.getFieldsValue() as FormValue;
        const params = filterBy(values);
        this.loading.download = true;
        params.params = await buildDownloadParam(values);
        const response = await downloadReport(params);
        toDownload(response, "unit_margin_report.xlsx");
      } catch (error) {
        this.showNotifError("notif_download_error");
      } finally {
        this.loading.download = false;
      }
    },
    handleCancel() {
      this.form.resetFields();
    },
    onSubmit(): void {
      const { findAll: findList, filterBy } = useUnitMargin();

      this.form.validateFields((_err, res: FormValue) => {
        const params = filterBy(res);

        this.loading.table = true;
        findList(params)
          .then(data => {
            this.dataReport = data;
            this.dataSource = this.dataReport.data.map<TableRow>(
              (item, idx) => ({
                ...item,
                key: idx,
              })
            );
          })
          .finally(() => (this.loading.table = false));
      });
    },
    getAssetCategory(params: RequestQueryParamsModel): void {
      const { findAll: findAssetCategory, toOptions: toCatOption } =
        useAssetCategory();

      const builder = new SearchBuilder();
      const copy = { ...params };
      const q: string[] = [
        // hardcoded to Commercial, nothing special, can replace in the future
        builder.push(["book.type", "Commercial"]).build(),
      ];
      if (copy.search) {
        q.push(copy.search);
      }
      params.search = q.join(builder.AND);
      this.loading.category = true;
      findAssetCategory(params)
        .then(response => {
          this.optAssetCategory = toCatOption(response.data);
        })
        .finally(() => {
          this.loading.category = false;
        });
    },
    onSearchAssetCategory(search = ""): void {
      const { filterBy: searchAssetCat } = useAssetCategory();

      const params = new RequestQueryParams();
      params.search = searchAssetCat({ categoryId: search });
      this.getAssetCategory(params);
    },
  },
});
