import { SupplierReturnLineState } from "@/models/interface/supplier-return";

export const buildSupplierReturnLineState = (): SupplierReturnLineState => ({
  key: "",
  productId: "",
  uomId: "",
  uom: "",
  productCode: "",
  grNumber: "",
  grLineId: "",
  apLineId: "",
  invoiceNumber: "",
  qtyReceivedInvoiced: 0,
  price: 0,
  qtyReturn: 0,
  total: 0,
  location: "",
  listLocation: [],
  qtyAvailableReturn: 0,
  serialNumbers: []
});
