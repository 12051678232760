



















































































































































































































































































































































































































































import ModalStateBuilder from "@/builder/ModalStateBuilder";
import SearchBuilder from "@/builder/SearchBuilder";
import { buildSupplierReturnLineState } from "@/builder/supplier-return/SupplierReturnStateBuilder";
import currencyFilter from "@/filters/currency.filter";
import quantityFilter from "@/filters/quantity.filter";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import useProduct from "@/hooks/useProduct";
import { SupplierReturnMapper } from "@/mapper/SupplierReturn.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  SupplierReturnRefundTypeEnum,
  SupplierReturnSourceTypeEnum,
} from "@/models/enums/supplier-return.enum";
import { ListContactDataDto } from "@/models/interface/contact-data";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductPurchaseResponseDto } from "@/models/interface/ProductPurchaseResponse.interface";
import type {
  FormFinderValues,
  FormValues,
  SupplierReturnLineLocationState,
  SupplierReturnLineState,
} from "@/models/interface/supplier-return";
import { PropsModel } from "@/models/interfaces/common.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import { logisticServices } from "@/services/logistic.service";
import { supplierReturnService } from "@/services/supplier-return.service";
import { ColumnDef } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class SupplierReturnFormView extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  quantityFilter = quantityFilter;

  docId = "";
  mode = "" as string;
  journalNumber = {
    id: "" as string,
    name: "" as string,
  };

  disabled = {
    notes: false,
    supplierName: false as boolean,
    returnFrom: false as boolean,
    documentNumber: false as boolean,
    grNumber: true as boolean,
    poNumber: true as boolean,
    invoiceNumber: true as boolean,
    branch: true as boolean,
    grDate: true as boolean,
    returnDate: false as boolean,
    journalNumber: false as boolean,
    currency: true as boolean,
    rate: true as boolean,
    refundOption: false as boolean,
    save: false as boolean,
    supplierNameResult: true as boolean,
    find: false as boolean,
    invoiceDate: false,
  };
  listSupplierName: ListContactDataDto[] = [];
  loading = {
    supplierName: false as boolean,
    save: false as boolean,
    documentNumber: false as boolean,
    find: false as boolean,
  };
  dataListItemsModal: SupplierReturnLineLocationState[] = [];
  columnsTableModal: ColumnDef[] = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      width: 150,
      customRender: text => text ?? "-",
    },
    {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      key: "qtyAvailable",
      width: 150,
      customRender: text => quantityFilter(text),
    },
    {
      title: this.$t("lbl_qty_return"),
      dataIndex: "qtyReturn",
      key: "qtyReturn",
      width: 150,
      scopedSlots: { customRender: "qtyReturn" },
    },
  ];

  columnsTable: ColumnDef[] = [
    {
      title: this.$t("lbl_good_receipt_number"),
      dataIndex: "grNumber",
      key: "grNumber",
      customRender: text => text ?? "-",
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      customRender: text => text ?? "-",
    },
    {
      title: this.$t("lbl_qty_received_invoiced"),
      dataIndex: "qtyReceivedInvoiced",
      align: "right",
      key: "qtyReceivedInvoiced",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_available_return"),
      dataIndex: "qtyAvailableReturn",
      align: "right",
      key: "qtyAvailableReturn",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      align: "right",
      key: "price",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_qty_return"),
      dataIndex: "qtyReturn",
      align: "right",
      key: "qtyReturn",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      align: "right",
      key: "total",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_location"),
      key: "location",
      align: "right",
      scopedSlots: { customRender: "location" },
    },
  ];
  dataListItems: SupplierReturnLineState[] = [];
  dataDetailFind = {
    branchId: "",
  };
  show = {
    invoiceNumber: false,
    invoiceDate: false,
    refundOption: false,
    poNumber: false,
    grDate: false,
    grNumber: false,
  };
  dataGrOrInvoiceNumber: { id: string; documentNumber: string }[] = [];
  formFind!: WrappedFormUtils;
  formDetail!: WrappedFormUtils;
  formRuleFind = {
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierId",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierId",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    returnFrom: {
      label: "lbl_return_from",
      name: "returnFrom",
      placeholder: "lbl_return_from",
      decorator: ["returnFrom"],
    },
    documentNumber: {
      label: "lbl_document_number",
      name: "documentNumber",
      placeholder: "lbl_document_number_placeholder",
      decorator: [
        "documentNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  isRefundRequired = false;
  selectedRecord: SupplierReturnLineState | undefined =
    buildSupplierReturnLineState();
  returnFromOption: { label: string; value: SupplierReturnSourceTypeEnum }[] = [
    {
      label: "lbl_good_receipt",
      value: "Good Receipt",
    },
    {
      label: "lbl_invoice",
      value: "Invoice",
    },
  ];

  refundOptions: { label: string; value: SupplierReturnRefundTypeEnum }[] = [
    {
      label: "lbl_refund",
      value: "REFUND",
    },
    {
      label: "lbl_debit_memo",
      value: "DEBIT_MEMO",
    },
  ];

  modalState = ModalStateBuilder.build({});

  beforeCreate(): void {
    this.formFind = this.$form.createForm(this, { name: "formFind" });
    this.formDetail = this.$form.createForm(this, { name: "formDetail" });
  }

  created(): void {
    this.mode = this.$route.meta.mode;
    this.docId = this.$route.params.id ?? "";
  }

  mounted(): void {
    this.getListSupplier("");
    if (this.mode === Mode.EDIT) this.getDetailData(this.docId);
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get isModeEdit(): boolean {
    return this.mode === Mode.EDIT;
  }

  get storeBaseDecimalPlace(): number {
    return this.$store.state.preferenceStore.baseDecimalPlace;
  }

  get formRuleResult() {
    return {
      documentNumber: {
        label: "lbl_supplier_return_number",
        name: "documentNo",
        placeholder: "lbl_document_number",
        decorator: ["documentNo"],
      },
      notes: {
        label: "lbl_notes",
        name: "notes",
        placeholder: "lbl_notes",
        decorator: ["notes"],
      },
      returnFrom: {
        label: "lbl_return_from",
        name: "returnFrom",
        placeholder: "lbl_return_from",
        decorator: ["returnFrom"],
      },
      supplierName: {
        label: "lbl_supplier_name",
        name: "supplierName",
        placeholder: "lbl_supplier_name",
        decorator: ["supplierName"],
      },
      grNumber: {
        label: "lbl_good_receipt_number",
        name: "grNumber",
        placeholder: "lbl_good_receipt_number",
        decorator: ["grNumber"],
      },
      poNumber: {
        label: "lbl_purchase_order_number",
        name: "poNumber",
        placeholder: "lbl_purchase_order_number",
        decorator: ["poNumber"],
      },
      grDate: {
        label: "lbl_good_receipt_date",
        name: "grDate",
        placeholder: "lbl_good_receipt_date",
        decorator: ["grDate"],
      },
      journalNumber: {
        label: "lbl_journal_number",
        name: "journalNumber",
        placeholder: "lbl_journal_number",
        decorator: ["journalNumber"],
      },
      currency: {
        label: "lbl_currency",
        name: "currency",
        placeholder: "lbl_currency",
        decorator: ["currency"],
      },
      rate: {
        label: "lbl_rate",
        name: "rate",
        placeholder: "lbl_rate",
        decorator: ["rate"],
      },
      returnDate: {
        label: "lbl_return_date",
        name: "returndate",
        placeholder: DEFAULT_DATE_FORMAT,
        decorator: [
          "returnDate",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
            initialValue: moment(),
          },
        ],
      },
      invoiceNumber: {
        label: "lbl_invoice_number",
        name: "invoicenumber",
        placeholder: "lbl_invoice_number",
        decorator: ["invoiceNumber"],
      },
      branch: {
        label: "lbl_branch",
        name: "branch",
        placeholder: "lbl_branch",
        decorator: ["branch"],
      },
      invoiceDate: {
        label: "lbl_invoice_date",
        name: "invoiceDate",
        placeholder: "lbl_invoice_date",
        decorator: ["invoiceDate"],
      },
      refundOption: {
        label: "lbl_refund_option",
        name: "refundOption",
        decorator: [
          "refundOption",
          {
            rules: [
              {
                required: this.isRefundRequired,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ],
      },
    };
  }

  /**
   * @description find inventory by product id and assign into
   * list location
   * @param key index row to assign location
   * @param productId product id
   */
  getListOfInventoryLine(key: number, productId: string): void {
    const params = new RequestQueryParams("available>0");
    if (productId) {
      params.search += `_AND_product.secureId~${productId}`;
    }
    logisticServices.getListInventoryLine(params).then(res => {
      const dataLocation = res.data.map<SupplierReturnLineLocationState>(
        item => ({
          key: generateUUID(),
          location: item.warehouseLocationName ?? "",
          locationId: item.warehouseLocationId ?? "",
          qtyAvailable: item.available,
          qtyReturn: 0,
          serialNumber: undefined,
          serialNumberOptions: [],
          loadingSerialNumber: false,
        })
      );
      this.dataListItems[key].listLocation = dataLocation;
    });
  }

  showLocation(state: SupplierReturnLineState): void {
    const index = this.dataListItems.findIndex(item => item.key === state.key);
    this.selectedRecord = { ...state };
    this.dataListItemsModal = this.dataListItems[index].listLocation.map(
      item => ({
        ...item,
        key: generateUUID(),
      })
    );
    this.modalState.open();

    if (this.docId) return;
    this.selectedRecord.listLocation.forEach((item, index) => {
      this.dataListItemsModal[index].serialNumberOptions = [];
      this.getSerialNumberOptions({
        productId: this.selectedRecord?.productId ?? "",
        locationId: item.locationId,
        docReference: this.selectedRecord?.grNumber ?? "",
        state: this.dataListItemsModal[index],
        serialNumber: this.dataListItems[index].serialNumbers[0] ?? "",
      });
    });
  }

  buildSerialNumberParams({
    docReference,
    productId,
    locationId,
    serialNumber,
  }: {
    docReference?: string;
    productId: string;
    locationId?: string;
    serialNumber?: string;
  }): RequestQueryParamsModel {
    const queries: string[] = [];
    queries.push(new SearchBuilder().push(["productId", productId]).build());

    if (docReference) {
      queries.push(
        new SearchBuilder().push(["documentReference", docReference]).build()
      );
    }
    if (locationId) {
      queries.push(
        new SearchBuilder().push(["locationId", locationId]).build()
      );
    }
    if (serialNumber) {
      queries.push(
        new SearchBuilder().push(["serialNumber", serialNumber]).build()
      );
    }
    return {
      search: queries.join(SearchBuilder.AND),
    };
  }

  async getSerialNumberOptions(arg: {
    productId: string;
    locationId: string;
    docReference: string;
    state: SupplierReturnLineLocationState;
    serialNumber: string;
  }): Promise<void> {
    try {
      const params = this.buildSerialNumberParams({
        productId: arg.productId,
        locationId: arg.locationId,
        docReference: arg.docReference,
        serialNumber: arg.serialNumber,
      });
      arg.state.loadingSerialNumber = true;
      const response = await this.getSerialNumberList(params);
      const defaultSerialNumber = response.find(res => res.key === arg.serialNumber);

      arg.state.serialNumberOptions = response;
      arg.state.serialNumber = defaultSerialNumber?.key as string;
      this.onChangeSerialNumber(arg.state, arg.state.serialNumber);
    } catch {
      arg.state.serialNumberOptions = [];
    } finally {
      arg.state.loadingSerialNumber = false;
    }
  }

  onModalClose(): void {
    this.dataListItemsModal = [];
    this.selectedRecord = undefined;
    this.modalState.close();
  }

  countTotal(state: SupplierReturnLineState): void {
    state.qtyReturn = state.listLocation.reduce(
      (n, { qtyReturn }) => n + qtyReturn,
      0
    );
    state.total = state.qtyReturn * state.price;
  }

  saveDataModal(): void {
    const index = this.dataListItems.findIndex(
      item => item.key === this.selectedRecord?.key
    );
    if (index !== -1) {
      this.dataListItems[index].listLocation = [...this.dataListItemsModal];
      this.countTotal(this.dataListItems[index]);
    }
    this.selectedRecord = undefined;
    this.dataListItemsModal = [];
    this.modalState.close();
  }

  getListSupplier(value: string): void {
    const params = new RequestQueryParams();
    params.search = `supplier~true_AND_active~true`;
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.supplierName = true;
    contactServices
      .getListContactData(params)
      .then(res => {
        this.listSupplierName = res.data;
      })
      .finally(() => (this.loading.supplierName = false));
  }

  /**
   * @description handler search fields
   * @param value search value
   * @param type which fields
   */
  searchDropdown(value: string, type: "supplierName" | "documentNumber"): void {
    debounce(() => {
      if (type === "supplierName") {
        this.getListSupplier(value);
      } else {
        this.changeDataReturnForm(value);
      }
    });
  }

  getInvoice(value: string | null): void {
    const params = new RequestQueryParams();
    params.sorts = "createdDate:desc";
    params.supplierId = this.formFind.getFieldValue("supplierId");
    if (value) {
      params.search = value;
    }
    this.loading.documentNumber = true;
    invoiceAPServices
      .listApInvoiceAvailable(params)
      .then(res => {
        const arrayUniqueByKey = [
          ...new Map(res.map(item => [item.documentNumber, item])).values(),
        ];
        this.dataGrOrInvoiceNumber = arrayUniqueByKey.map(item => ({
          id: item.id,
          documentNumber: item.documentNumber,
        }));
      })
      .finally(() => (this.loading.documentNumber = false));
  }

  getGr(value: string | null): void {
    const params = new RequestQueryParams();
    params.status = "UN_BILLED";

    if (this.formFind.getFieldValue("supplierId")) {
      params.supplierId = this.formFind.getFieldValue("supplierId");
    }
    if (value) params.receiveNumber = value;
    this.loading.documentNumber = true;
    supplierReturnService
      .getListGrReturnToSupplier(params)
      .then(res => {
        this.dataGrOrInvoiceNumber = res.data.map(item => ({
          id: item.id,
          documentNumber: item.receiveNumber,
        }));
      })
      .finally(() => (this.loading.documentNumber = false));
  }

  onChangeSupplier(value: string | null): void {
    this.formFind.setFieldsValue({
      supplierId: value,
    });
    if (!value) return;
    this.findDocs();
  }

  onChangeReturnFrom(value: string | null): void {
    this.formFind.setFieldsValue({
      returnFrom: value,
    });
    if (!value) return;
    this.findDocs();
  }

  /**
   * @description handler dropdown changes
   */
  findDocs(): void {
    this.formFind.setFieldsValue({
      documentNumber: undefined,
    });

    // get latest value return from
    const returnFrom: SupplierReturnSourceTypeEnum =
      this.formFind.getFieldValue("returnFrom");

    if (returnFrom === "Good Receipt") {
      this.show.refundOption = false;
      this.show.invoiceNumber = false;
      this.show.invoiceDate = false;
      this.show.grDate = true;
      this.show.poNumber = true;
      this.show.grNumber = true;
      this.isRefundRequired = false;
    } else if (returnFrom === "Invoice") {
      this.show.refundOption = true;
      this.show.invoiceNumber = true;
      this.show.invoiceDate = true;
      this.show.grDate = false;
      this.show.poNumber = false;
      this.show.grNumber = false;
      this.isRefundRequired = true;
    }

    this.changeDataReturnForm(null);
  }

  changeDataReturnForm(value: string | null): void {
    const returnFrom: SupplierReturnSourceTypeEnum =
      this.formFind.getFieldValue("returnFrom");
    if (returnFrom === "Good Receipt") {
      this.getGr(value);
    } else {
      this.getInvoice(value);
    }
  }

  /**
   * @description find document data based on type
   * @param value document number
   */
  detailData(value: string): void {
    const returnFrom: SupplierReturnSourceTypeEnum =
      this.formFind.getFieldValue("returnFrom");
    if (returnFrom === "Good Receipt") {
      this.detailGr(value);
    } else {
      this.detailInvoice(value);
    }
  }

  /**
   * @description find document GR based on document number
   * @param value document number
   */
  detailGr(value: string): void {
    this.loading.find = true;
    supplierReturnService
      .getDetailGrReturnToSupplier(value)
      .then(res => {
        this.journalNumber.id = res.journalNumberId as string;
        this.journalNumber.name = res.journalNo as string;
        this.dataDetailFind.branchId = res.branchWarehouseId as string;
        this.formDetail.setFieldsValue({
          supplierName: res.supplierName,
          grNumber: res.receiveNumber,
          poNumber: res.purchaseOrderNumber,
          grDate: res.receiveDate
            ? moment(res.receiveDate).format(DEFAULT_DATE_FORMAT)
            : null,
          branch: res.branchWarehouse,
          currency: res.currencyCode,
          rate: res.currencyRate,
        });
        res.receiveItems.forEach((item, index) => {
          this.dataListItems.push({
            key: index,
            productId: item.productId,
            productCode: item.productCode,
            uom: item.produtUom,
            uomId: item.productUomId,
            grNumber: res.receiveNumber as string,
            grLineId: item.id as string,
            qtyReceivedInvoiced: item.qty,
            price: item.price,
            qtyReturn: 0,
            total: 0,
            location: "",
            listLocation: [],
            qtyAvailableReturn: item.qtyAvailableForReturn || 0,
            apLineId: "",
            invoiceNumber: "",
            serialNumbers: item.serialNumbers,
          });
          this.getListOfInventoryLine(index, item.productId);
        });
      })
      .finally(() => (this.loading.find = false));
  }

  /**
   * @description find available AP invoice for return
   * @param value document number
   */
  detailInvoice(value: string): void {
    this.loading.find = true;
    invoiceAPServices
      .detailApInvoiceReturn(value)
      .then(res => {
        this.journalNumber.id = res.journalId;
        this.journalNumber.name = res.journalNo;
        this.dataDetailFind.branchId = res.branchWarehouseId;
        this.formDetail.setFieldsValue({
          supplierName: res.supplierName,
          branch: res.branchWarehouseName,
          currency: res.currency,
          invoiceNumber: res.documentNumber,
          invoiceDate: res.invoiceDate
            ? moment(res.invoiceDate).format()
            : null,
          rate: res.currencyRate,
        });
        res.invoiceAPLines.forEach((item, index) => {
          this.dataListItems.push({
            key: generateUUID(),
            productId: item.productId,
            productCode: item.partNumber,
            uom: item.uom,
            uomId: item.uomId,
            grNumber: item.documentReference,
            grLineId: "",
            qtyReceivedInvoiced: item.qty,
            price: item.price,
            qtyReturn: 0,
            total: 0,
            location: "",
            listLocation: [],
            qtyAvailableReturn: item.qtyAvailableForReturn || 0,
            apLineId: item.id || "",
            invoiceNumber: item.documentReference,
            serialNumbers: [],
          });
          this.getListOfInventoryLine(index, item.productId);
        });
      })
      .finally(() => (this.loading.find = false));
  }

  /**
   * @description find data based on form finder
   */
  findData(): void {
    this.formFind.validateFields((err, value) => {
      if (err) return;
      this.dataDetailFind.branchId = "";
      this.dataListItems = [];
      this.detailData(value.documentNumber);
    });
  }

  /**
   * @description get detail document
   * @param id document secure id
   */
  getDetailData(id: string): void {
    supplierReturnService.getDetailReturnToSupplier(id).then(res => {
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }

      this.formDetail.setFieldsValue({
        notes: res.notes,
        supplierName: res.supplierName,
        branch: res.branchName,
        poNumber: res.poNumber,
        returnDate: moment(res.returnDate),
        currency: res.currency,
        rate: res.rates,
        documentNo: res.documentNo,
        returnFrom: res.returnFrom === "GR_PRICE" ? "Good Receipt" : "Invoice",
      });
      this.journalNumber.id = res.journalNumberId;
      this.journalNumber.name = res.journalNumber;
      res.detailLines.forEach(item => {
        const qtyAvailableReturn: number = new Decimal(item.qty || 0)
          .minus(item.qtyReturn || 0)
          .toNumber();
        this.dataListItems.push({
          qtyAvailableReturn,
          key: generateUUID(),
          grNumber: item.grNumber,
          apLineId: item.apLineId,
          invoiceNumber: item.invoiceNumber || "",
          grLineId: item.grLineId,
          productCode: item.productCode,
          uom: item.uom,
          uomId: item.uomId,
          productId: item.productId,
          qtyReceivedInvoiced: item.qty,
          price: item.price,
          qtyReturn: item.qtyReturn,
          total: item.totalPrice,
          location: "",
          serialNumbers: [],
          listLocation: item.locationDataList.map(data => {
            return {
              key: generateUUID(),
              location: data.location,
              locationId: data.locationId,
              qtyAvailable: data.qtyAvailable,
              qtyReturn: data.qtyReturn,
              serialNumber: data.serialNumber ?? undefined,
              serialNumberOptions: [],
              loadingSerialNumber: false,
            };
          }),
        });
      });
      if (res.returnFrom === "GR_PRICE") {
        this.show.invoiceNumber = false;
        this.show.invoiceDate = false;
        this.show.refundOption = false;
      } else if (res.returnFrom === "INVOICE_AP") {
        this.show.invoiceNumber = true;
        this.show.invoiceDate = true;
        this.show.refundOption = true;
        this.formDetail.setFieldsValue({
          invoiceNumber: res.invoiceNumber,
          invoiceDate: moment(res.invoiceDate).format(),
        });
      }
      const data = {
        grNumber: res.grNumber,
        grDate: res.grDate,
        refundOption: res.refundOptions,
      };
      this.setDataForm(data);
    });
  }

  setDataForm(values): void {
    for (const key in values) {
      this.formDetail.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formDetail.setFieldsValue(values);
  }

  validateForm(): void {
    this.formFind.validateFields((err, values) => {
      if (err) return;

      this.formDetail.validateFields((err, valueResult) => {
        if (err) return;

        this.save(
          {
            finder: values,
            detail: valueResult,
            additional: this.dataDetailFind,
          },
          this.dataListItems
        );
      });
    });
  }

  async save(
    params: {
      finder: FormFinderValues;
      detail: FormValues;
      additional: PropsModel;
    },
    lines: SupplierReturnLineState[]
  ): Promise<void> {
    try {
      this.loading.save = true;
      const request = SupplierReturnMapper.toCreateDto(params, lines);
      const response = await supplierReturnService.createReturnToSupplier(
        request
      );
      this.showNotifSuccess("notif_create_success", {
        documentNumber: response.documentNo,
      });
      this.$router.push({ name: "purchasing.transaction.supplier-return" });
    } catch (error) {
      this.showNotifError("notif_create_fail");
    } finally {
      this.loading.save = false;
    }
  }
  back() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push({ name: "purchasing.transaction.supplier-return" });
      },
      onCancel() {
        return;
      },
    });
  }

  async getSerialNumberList(
    params?: RequestQueryParamsModel
  ): Promise<Option<ProductPurchaseResponseDto>[]> {
    const { findAllSnForSales } = useProduct();
    try {
      const response = await findAllSnForSales(params);
      return response.map(item => ({
        label: item.serialNumber,
        value: item.serialNumber,
        key: item.serialNumber,
        meta: item,
      }));
    } catch {
      return [];
    }
  }

  onChangeSerialNumber(
    record: SupplierReturnLineLocationState,
    val?: string
  ): void {
    record.qtyReturn = 0;
    const option = record.serialNumberOptions.find(item => item.value === val);
    if (!option || !option.meta) return;
    record.qtyReturn = option.meta.qtyAvailable;
    record.location = option.meta.location;
  }
}
